import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import DataViewer from '~utils/DataViewer'
import resolveLink from '~utils/resolveLink'
import { mobile, tablet } from '~styles/global'
import {CrossMob, CrossDesk, LineMob, LineDesk} from '~components/Svg'
import { useSiteState } from '~context/siteContext'
import { useLocation } from '@reach/router'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'
import SignUpForm from '~components/SignUpForm'

const Header = ({ className }) => {

	const { signUpFormMessage } = useSiteSettings()

	const location = useLocation()
	const [siteState, setSiteState] = useSiteState()

	const navData = useStaticQuery(graphql`
		{
			pages: allSanityPage(sort: {order: ASC, fields: rank}) {
				nodes {
					_type
					slug {
						current
					}
					title
					rank
					parentPlace {
						_type
						slug{
							current
						}
						rank
						title
						featureColor{
							hex
						}
					}
				}
			}
		}
	`)

	var groupPages = function(pages) {
		return pages.reduce(function(rv, page) {
			(rv[page?.parentPlace?.title] = rv[page?.parentPlace?.title] || []).push(page)
			return rv
		}, {})
	}

	let menuItems = groupPages(navData?.pages?.nodes)

	menuItems = Object.keys(menuItems).map(key => ({
		parentPlace: {...menuItems[key][0].parentPlace},
		items: menuItems[key],
	}))
	menuItems.sort((a, b) => a.parentPlace.rank - b.parentPlace.rank)

	useEffect(() => {
		setSiteState(prevState => ({
			...prevState,
			menuOpen: false,
		}))
	}, [location])

	return(
		<>
			<Wrap 
				className={className} 
				css={css`
				pointer-events: ${siteState.menuOpen ? 'all' : 'none'};
		`}>
				<ToggleOpen 
					onClick={() => setSiteState(prevState => ({
						...prevState,
						menuOpen: true,
					}))}
				>
					<OpenMob />
					<Open />
				</ToggleOpen>
				<Nav css={css`transform: ${siteState.menuOpen ? 'translateX(0px)' : 'translateX(100%)'};`}>
					<Top css={css`color: ${siteState.menuOpen ? 'var(--white)' : 'var(--black)'};`}>
						<TopInner>
							<SiteTitle>Jacky Winter</SiteTitle>
							<ToggleCloseMob 
								onClick={() => setSiteState(prevState => ({
									...prevState,
									menuOpen: false,
								}))}
								css={css`color: ${siteState.menuOpen ? 'var(--white)' : 'var(--black)'};`}
							>
								<CloseMob />
							</ToggleCloseMob>
						</TopInner>
					</Top>
					<MenuGroupFirst css={css`background-color: var(--black);`}> 
						<Line css={css`${mobile}{border-top: 1px solid var(--white); margin-top: 1px;}`}>
							<ItemTitle>Places</ItemTitle>
							<Item to={'/'}><Bullet>○</Bullet>Home</Item>
						</Line>
						<ToggleClose
							onClick={() => setSiteState(prevState => ({
								...prevState,
								menuOpen: false,
							}))}
						>
							<Close />
						</ToggleClose>
					</MenuGroupFirst>
					{menuItems?.map((item, i) => (
						<MenuGroup 
							key={item?.parentPlace?.title} 
							css={css`
							background-color: ${item.parentPlace?.featureColor?.hex};
							/* flex: ${(menuItems?.length === i + 1) ? '1' : '0'}; */
					`}>
							{item.items.map((page, i) => (
								<Line key={page?.slug?.current}>
									{i === 0 && <ItemTitle>{item?.parentPlace?.title}</ItemTitle>}
									<Item to={resolveLink(page)}><Bullet>○</Bullet>{page?.title}</Item>
								</Line>
							))}
						</MenuGroup>
					))}
					<MenuGroup 
						key='LD'
						css={css`
							background-color: #FF5331;
							/* flex: 1; */
					`}>
						<Line >
							<ItemTitle>Lamington Drive</ItemTitle>
							<ExternalItem href='https://lamingtondrive.com/' target="_blank"><Bullet>○</Bullet>Visit LD Website</ExternalItem>
						</Line>
					</MenuGroup>
					<SignUpBlock>
						<SignUpTitle>Places News</SignUpTitle>
						<SignUpText><RichText content={signUpFormMessage}/></SignUpText>
						<StyledSignUpForm successMessage={'Got it, thanks!'}/> 
					</SignUpBlock>
				</Nav>
				<DataViewer data={menuItems} name="menuItems" />
			</Wrap>
		</>
	)
}

const Wrap = styled.header`
  position: fixed;
  top: 0;
  right: 0;
	width: 550px;
	height: 100vh;
	z-index: 15;
	display: flex;
	flex-direction: column;
	${mobile}{
		width: 100%;
	}
`
const Top = styled.div`
	display: none;
	${mobile}{
		padding: var(--s) var(--xl) 0;
		display: block;
	}
`
const TopInner = styled.div`
	display: flex;
	justify-content: flex-end;
	height: 32px;
	${mobile}{
		padding-bottom: 4px;
		height: auto;
		justify-content: space-between;
	}
`
const SiteTitle = styled.div`
	font-weight: 600;
	display: none;
	${mobile}{
		display: block;
		pointer-events: all;
	}
`
const ToggleOpen = styled.button`
	pointer-events: all;
	width: 21px;
	align-self: flex-end;
	margin: var(--xl);
	${mobile}{
		width: 31px;
		margin: var(--m);
	}
`
const ToggleClose = styled.button`
	pointer-events: all;
	width: 21px;
	display: grid;
  align-items: center;
	color: var(--white);
	${mobile}{
		width: 31px;
		display: none;
	}
`
const ToggleCloseMob = styled.button`
	display: none;
	color: var(--white);
	${mobile}{
		width: 31px;
		display: block;
	}
`
const OpenMob = styled(CrossMob)`
	display: none;
	${mobile}{
		display: block;
	}
`
const Open = styled(CrossDesk)`
	${mobile}{
		display: none;
	}
`
const CloseMob = styled(LineMob)`
	display: none;
	${mobile}{
		display: block;
	}
`
const Close = styled(LineDesk)`
	${mobile}{
		display: none;
	}
`
const Nav = styled.nav`
	display: flex;
	flex-direction: column;
	color: var(--white);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* height: -webkit-fill-available; */
	z-index: 12;
	background-color: var(--black);
	transition: transform 0.6s; 
	overflow-y: scroll;
	/* border: 1px solid red; */
	${mobile}{
		padding-bottom: 90px;
	}
`
const MenuGroup = styled.div`
	padding: 0 var(--xl);
`
const MenuGroupFirst = styled(MenuGroup)`
	padding-top: 16px;
	display: grid;
	grid-template-columns: 1fr max-content; 
	${mobile}{
		padding-top: 0;
		display: block;
	}
`
const Line = styled.div`
	display: grid;
	grid-template-columns: 186px 1fr;
	border-bottom: 1px solid var(--black);
	&:last-of-type{
		margin-bottom: -1px;
	}
	${mobile}{
		grid-template-columns: 33.333% 66.666%;
	}
`
const ItemTitle = styled.div`
	padding: var(--xxxs) 0;
	font-weight: 600;
	padding-right: var(--s);
`
const Item = styled(Link)`
	display: flex;
	grid-column: 2/3;
	padding: var(--xxxxs) 0;
`

const ExternalItem = Item.withComponent('a')

const Bullet = styled.span`
	display: inline-block;
	margin-right: var(--xs);
	${mobile}{
		margin-right: var(--xxxs);
	}
`
const SignUpBlock = styled.div`
	flex: 1;
	padding: var(--l) var(--xl);
	display: grid;
	grid-template-columns: 186px 1fr;
	grid-template-rows: repeat(2, max-content);
	${mobile}{
		grid-template-columns: 33.333% 66.666%;
	}
`

const SignUpTitle = styled.div`
	font-weight: 600;
`

const SignUpText = styled.div`
	
`

const StyledSignUpForm = styled(SignUpForm)`
	margin-top: var(--xxxl);
	grid-column: 1/3;
`

Header.propTypes = {
	className: PropTypes.string,
}

export default Header
